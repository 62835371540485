import {Accordion} from '../types/accordion.types'
import {ComponentAccordionFieldsFragment} from '../../../../__generated__/datocms.types'
import {parseRichTextBlocks, parseRichTextLinks} from './rich-text.serde'
import {validateDatoCmsTypes} from './common.serde'

export function parseAccordion(a: ComponentAccordionFieldsFragment): Accordion {
  const accordion = validateDatoCmsTypes(a)

  return {
    elements: accordion.elements
      .filter(i => !!i?.content)
      .map(i => i!)
      .map(({title, content}) => {
        return {
          title: title!,
          content: {
            json: content!.json,
            links: parseRichTextLinks(content?.links ?? []),
            blocks: parseRichTextBlocks(content?.blocks ?? []),
          },
        }
      }),
  }
}
