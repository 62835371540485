import {
  DatoRichTextBlock,
  DatoRichTextLinks,
  RichBulletPointDato,
  RichButton,
  RichVideo,
} from '../types/rich-text.types'
import {parsePromotionalBannerSection} from './promotional-banner-section.serde'
import {parseAccordion} from './accordion.serde'
import {
  ArticlePageFieldsFragment,
  ComponentAccordionFieldsFragment,
  ComponentAccordionModelRecord,
  ComponentBulletPointFieldsFragment,
  ComponentButtonFieldsFragment,
  ContentSectionFieldsFragment,
  IndustryOverviewPageFieldsFragment,
  OfferingDetailPageFieldsFragment,
  PromotionalBannerSectionFieldsFragment,
  SignupSectionFieldsFragment,
  StaticPageFieldsFragment,
  VideoFieldsFragment,
} from '../../../../__generated__/datocms.types'
import {parseSignupSection} from './signup-section.serde'

type DatoRichTextGqlLinks =
  | ComponentAccordionFieldsFragment
  | ComponentBulletPointFieldsFragment
  | VideoFieldsFragment
  | ComponentButtonFieldsFragment
  | PromotionalBannerSectionFieldsFragment
  | SignupSectionFieldsFragment

export function parseRichTextLinks(links: DatoRichTextGqlLinks[]): Map<string, DatoRichTextLinks> {
  const result = new Map<string, DatoRichTextLinks>()

  links.forEach(e => {
    if (e?.__typename === 'ComponentBulletPointModelRecord') {
      const id = e.id
      const entry = parseRichBulletPoint(e)
      if (entry) {
        result.set(id, {type: 'componentBulletPoint', entry})
      }
    } else if (e?.__typename === 'ComponentButtonModelRecord') {
      const id = e.id
      const entry = parseRichButton(e)
      if (entry) {
        result.set(id, {type: 'componentButton', entry})
      }
    } else if (e?.__typename === 'PromotionBannerModelRecord') {
      const id = e.id
      result.set(id, {type: 'fieldPromotionBanner', entry: parsePromotionalBannerSection(e)})
    } else if (e?.__typename === 'VideoModelRecord') {
      const id = e.id
      const entry = parseRichVideo(e)
      if (entry) {
        result.set(id, {type: 'video', entry})
      }
    } else if (e?.__typename === 'ComponentAccordionModelRecord') {
      const id = (e as ComponentAccordionModelRecord).id
      result.set(id, {type: 'componentAccordion', entry: parseAccordion(e)})
    } else if (e?.__typename === 'FieldSignupSectionRecord') {
      const id = e.id
      result.set(id, {type: 'fieldSignupSection', entry: parseSignupSection(e)})
    }
  })

  return result
}

function parseRichBulletPoint(e: ComponentBulletPointFieldsFragment): RichBulletPointDato | undefined {
  const {name, collection} = e.icon || {}
  if (!e.lead || !e.icon || !e.iconColor || !name || !collection) {
    console.warn('RichBulletPoint is missing required fields', e)
    return undefined
  }

  return {
    lead: e.lead,
    text: e.text,
    // inline blocks are not supported
    content: e.content?.json,
    icon: {name, collection},
    iconColor: e.iconColor,
  }
}

function parseRichButton(e: ComponentButtonFieldsFragment): RichButton | undefined {
  if (!e.label || !e.targetPath || !e.typeField) {
    console.warn('RichButton is missing required fields', e)
    return undefined
  }

  return {
    label: e.label,
    // DatoCMS doesn't have typed enums, but string validation is setup
    type: e.typeField as 'Primary' | 'Secondary',
    targetPath: e.targetPath,
  }
}

function parseRichVideo(e: VideoFieldsFragment): RichVideo | undefined {
  if (!e.youtubeId) {
    console.warn('RichVideo is missing required fields', e)
    return undefined
  }
  return {
    youtubeId: e.youtubeId,
  }
}

export function parseRichTextBlocks(blocks: DatoRichTextBlock[]): Map<string, DatoRichTextBlock> {
  const result = new Map<string, DatoRichTextBlock>()

  blocks.forEach(a => {
    switch (a?.__typename || '') {
      case 'StructuredTextAssetRecord':
      case 'TableRecord':
      case 'StructuredTextPlotRecord':
        result.set(a.id, a)
        break
    }
  })

  return result
}

export function parseRichTextContent(
  entry:
    | Pick<ContentSectionFieldsFragment, 'content'>
    | Pick<StaticPageFieldsFragment, 'content'>
    | Pick<OfferingDetailPageFieldsFragment, 'content'>
    | Pick<IndustryOverviewPageFieldsFragment, 'content'>
    | Pick<ArticlePageFieldsFragment, 'content'>,
) {
  return {
    json: entry.content?.value,
    blocks: parseRichTextBlocks(entry.content?.blocks || []),
    links: parseRichTextLinks(entry.content?.links || []),
  }
}
