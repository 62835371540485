import {PromotionalBannerSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {PromotionalBannerSection} from '../types/promotional-banner-section.types'
import {validateDatoCmsTypes} from './common.serde'
import {parseCallToAction} from './call-to-action.serde'
import {parseAsset2, parseAssetWithSize} from './asset.serde'

export function parsePromotionalBannerSection(s: PromotionalBannerSectionFieldsFragment): PromotionalBannerSection {
  const section = validateDatoCmsTypes(s)

  return {
    __type: 'PromotionalBannerSection',
    id: section.id,
    title: section.title,
    content: section.contentText,
    callToAction: parseCallToAction(section.callToAction),
    background: parseAsset2(section.background),
    partnerLogo: section.partnerLogo ? parseAssetWithSize(section.partnerLogo) : undefined,
  }
}
